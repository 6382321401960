import React from 'react';
import styles from './BorrowersCalculatorApply.module.scss';
import Image from '@app/components/server/Image/Image';
import BecomeBorrowerForm from '@app/components/client/borrowers/BecomeBorrowerForm/BecomeBorrowerForm';

interface IProps {
    /**
     * Текст на лид кнопке
     */
    leadButtonText?: string;
}

export default function BorrowersCalculatorApply({
    leadButtonText = 'Подать заявку на займ',
}: IProps) {
    return (
        <div className={styles['container']}>
            <div>
                <h3 className={styles['title']}>{leadButtonText}</h3>
                <div className={styles['text']}>
                    И получите решение
                    за 3 минуты по 2-м документам
                    онлайн, на счет в любом банке.
                </div>
            </div>
            <BecomeBorrowerForm containerClassName={styles['apply-button-container']} buttonText={leadButtonText} />
            <div className={styles['image']}>
                <Image src="/next/assets/lines.svg" alt="" fill />
            </div>
        </div>
    );
};
