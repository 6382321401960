'use client';

import Button from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import { connectFunctionalComponent } from '@frontend/jetlend-core/src/ducks/connect';
import { IFormProps } from '@frontend/jetlend-core/src/models/form';
import React, { useCallback } from 'react';
import { MaskInputField } from '@frontend/jetlend-web-ui/src/ui/inputs/MaskInput/MaskInput';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import { useDispatch } from 'react-redux';
import { redirectToExternal } from '@frontend/jetlend-core/src/ducks/router';
import styles from './BecomeInvestorForm.module.scss';
import SectionPart from '@app/components/server/ui/SectionPart/SectionPart';
import { investorsPhoneFormHandler } from '@app/ducks/investors/investors';
import { startRegistration } from '@app/ducks/common/registration';
import { ClientType } from '@app/models/common/common';
import { sendEvent } from '@app/ducks/common/analytics';
import { getCorrectFirstPhoneNumberFigure } from '@app/utils/getCorrectFirstPhoneNumberFigure';
import { accountInfoHandler } from '@app/ducks/common/account';

interface IProps {
    type?: 'section-part' | 'short' | 'button' | 'button-block';
    withShadow?: boolean;
    buttonText?: string;
    /**
     * Отправлять на страницу пополнения, если пользователь авторизован
     */
    addFundsWhenAuth?: boolean;
}

type Props = IProps &
    IFormProps<any> & {
        startRegistration: typeof startRegistration;
        sendEvent: typeof sendEvent;
    };

const BecomeInvestorForm: React.FC<Props> = props => {
    const {
        type = 'section-part',
        withShadow,
        startRegistration,
        buttonText,
        addFundsWhenAuth,
    } = props;

    const text = buttonText || 'Начать инвестировать';

    const [account] = useSimpleApiHandler(accountInfoHandler);
    const dispatch = useDispatch();

    const didBecomeInvestorManualButtonClicked = useCallback(() => {
        if (addFundsWhenAuth && account && account.user_id) {
            dispatch(redirectToExternal(`${document.location.origin}/invest/v3#modal-addfunds`));
            return;
        }

        startRegistration(ClientType.Investor);

        props.sendEvent('investor-button');
    }, [addFundsWhenAuth, account]);

    const input = (
        <div className={styles['input']}>
            <MaskInputField
                mask="+7 000 000-00-00"
                prepareChar={getCorrectFirstPhoneNumberFigure}
                label="Номер телефона...."
                name="phone"
                inputType="tel"
                autoComplete="tel"
                className={styles['input-component']}
            />
        </div>
    );

    const button = (
        <Button text={text} size="big" onClick={props.handleSubmit} className={styles['button']} width={250} />
    );

    if (type === 'section-part') {
        return (
            <SectionPart withShadow={withShadow} className={styles['section']}>
                <form className={styles['container']} onSubmit={props.handleSubmit} data-testid="become-client-form">
                    <p className={styles['call-to-action']}>Начните инвестировать сегодня</p>
                    <hr className={styles['delimiter']} />
                    {input}
                    {React.cloneElement(button, {
                        buttonType: 'submit',
                        className: buildClassNames(styles, ['button', 'button--with-input']),
                        textClassName: styles['button--with-input__text'],
                    })}
                </form>
            </SectionPart>
        );
    }

    if (type === 'short') {
        return (
            <form className={styles['container']} onSubmit={props.handleSubmit} data-testid="become-client-form">
                {input}
                {React.cloneElement(button, {
                    buttonType: 'submit',
                    className: buildClassNames(styles, ['button', 'button--with-input']),
                    textClassName: styles['button--with-input__text'],
                })}
            </form>
        );
    }

    if (type === 'button' || type === 'button-block') {
        return (
            <Button
                text={text}
                size="big"
                onClick={didBecomeInvestorManualButtonClicked}
                width={type === 'button' ? 250 : undefined}
                block={type === 'button-block'}
                noSpacing
                containerClassName={styles['buttonContainer']}
            />
        );
    }
};

export default connectFunctionalComponent(BecomeInvestorForm, {
    form: investorsPhoneFormHandler,
    dispatch: {
        startRegistration,
        sendEvent,
    },
}) as React.FC<IProps>;
