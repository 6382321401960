import React from 'react';
import styles from './PartnerCalculatorApplyCard.module.scss';
import SectionPart from '@app/components/server/ui/SectionPart/SectionPart';
import Image from '@app/components/server/Image/Image';
import BecomePartnerForm from '@app/components/client/partners/BecomePartnerForm/BecomePartnerForm';


interface IProps {
    className?: string;

    /**
     * Текст на лид кнопке
     */
    leadButtonText?: string;
}

export default function PartnerCalculatorApplyCard({
    leadButtonText,
    className,
}: IProps) {
    return (
        <SectionPart className={className}>
            <div className={styles['content']}>
                <h3 className={styles['title']}>Станьте нашим партнером</h3>
                <p className={styles['description']}>
                    Пройдите регистрацию и получите доступ в личный кабинет для начала работы
                </p>
                <div className={styles['button-wrapper']}>
                    <BecomePartnerForm block className={styles['button']} buttonText={leadButtonText} />
                </div>
            </div>
            <div className={styles['image-wrapper']}>
                <Image src="/next/assets/partners-main.png" alt="" fill />
            </div>
        </SectionPart>
    );
};
