'use client';

import React from 'react';
import { formatDate } from '@frontend/jetlend-core/src/formatters/formatUtils';
import Image from '../../Image/Image';
import styles from './YouTubeItem.module.scss';
import { IYouTubeItemApiModel } from '@app/models/common/common';

type Props = IYouTubeItemApiModel;

const YouTubeItem: React.FC<Props> = props => (
    <div
        key={props.url}
        className={styles['container']}
    >
        <div className={styles['img']}>
            <Image
                src={props.preview_url}
                alt=""
                className={styles['preview']}
                fill
            />
            <Image
                className={styles['icon']}
                src="/next/assets/yt-icon.svg"
                alt=""
                width={60}
                height={60}
            />
        </div>
        <div className={styles['content']}>
            <div className={styles['top-block']}>
                <div className={styles['date']}>{formatDate(props.date)}</div>
                <h3 className={styles['title']}>{props.title}</h3>
            </div>
            {props.author && (
                <div className={styles['bottom-block']}>
                    <Image
                        className={styles['author-icon']}
                        src={props.author.icon}
                        alt=""
                        width={44}
                        height={44}
                    />
                    <div className={styles['name']}>{props.author.name}</div>
                    {props.author.verified && (
                        <Image
                            src="/next/assets/verified-icon.svg"
                            alt=""
                            width={12}
                            height={12}
                            unoptimized
                        />
                    )}
                </div>
            )}
        </div>
    </div>
);

export default YouTubeItem;