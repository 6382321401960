import { FC } from 'react';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './DecorativeRectangles.module.scss';

import Image from '@app/components/server/Image/Image';
import RightChevron from '@app/icons/arrow-right.svg';

interface IProps {
    titleText: string;
    descriptionText: string;
}

const DecorativeRectangles: FC<IProps> = props => {
    const {
        titleText,
        descriptionText,
    } = props;
    return (
        <div className={styles['rectangles']}>
            <div className={styles['rectangle']} />
            <div className={styles['rectangle']} />
            <div className={buildClassNames(styles, ['rectangle', 'rectangle--highlighted'])}>
                <div className={styles['image']}>
                    <Image src="/next/assets/personal-manager.png" alt="manager" width={55} height={55} />
                </div>
                <div className={styles['manager-info']}>
                    <div className={styles['manager-name']}>{titleText}</div>
                    <div className={styles['manager-description']}>{descriptionText}</div>
                </div>
                <Image src={RightChevron} alt="chevron" width={26} height={26} className={styles['manager-chevron']} />
            </div>
            <div className={styles['rectangle']} />
            <div className={styles['rectangle']} />
        </div>
    );
};

export default DecorativeRectangles;
