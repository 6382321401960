import {
    buildClassNames,
    mergeClassNames,
} from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import {
    MouseEvent,
    useCallback,
    useMemo,
} from 'react';
import { IDocument } from '@app/models/documents/documents';
import DownloadIcon from '@app/icons/download.svg';
import DownloadActiveIcon from '@app/icons/download--active.svg';
import LinkIcon from '@app/icons/arrow--primary.svg';
import LinkActiveIcon from '@app/icons/arrow--white.svg';
import styles from './DocumentCard.module.scss';
import Image from '@app/components/server/Image/Image';

interface IProps {
    document: IDocument;
    className?: string;
}

const DocumentCard : React.FC<IProps> = props => {
    const {
        document,
        className,
    } = props;

    const {
        title,
        description,
        external,
        file,
        url,
        url_slug: slug,
    } = document;

    const targetFileUrl = external
        ? url
        : file?.url;

    const didLinkClicked = useCallback((event: MouseEvent<HTMLAnchorElement>) => event.stopPropagation(), []);
    const didCardClicked = useCallback(() => {
        if (slug) {
            window.open(`/doc-view/${slug}`);
            return;
        }

        if (targetFileUrl) {
            window.open(targetFileUrl, '_blank');
        }
    }, [ slug, targetFileUrl ]);

    const [Icon, ActiveIcon] = useMemo(() => {
        if (external) {
            return [LinkIcon, LinkActiveIcon];
        }
        return [DownloadIcon, DownloadActiveIcon];
    }, [ external ]);

    return (
        <button
            className={mergeClassNames([buildClassNames(styles, ['document-card', 'document-card--clickable']), className])}
            onClick={didCardClicked}
            type="button"
        >
            <h6 className={styles['title']}>{title}</h6>
            <p className={styles['description']}>{description}</p>
            <div className={styles['download-wrapper']}>
                <a
                    className={styles['download-link']}
                    onClick={didLinkClicked}
                    href={targetFileUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                    download={!!file}
                >
                    <Image
                        className={styles['download-icon']}
                        src={Icon}
                        alt=""
                        width={18}
                        height={18}
                    />
                    <Image
                        className={styles['download-icon']}
                        src={ActiveIcon}
                        alt=""
                        width={18}
                        height={18}
                    />
                </a>
            </div>
        </button>
    );
};

export default DocumentCard;

