import {
    FC,
    useMemo,
} from 'react';

import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { IPartnersFeatureBlockApiModel } from '@app/models/partners/partners';
import styles from './ServiceFeature.module.scss';
import PartnerWorkflowSlider from '../../PartnerWorkflowSlider/PartnerWorkflowSlider';
import Image from '@app/components/server/Image/Image';

interface Props {
    sectionBlocks: Record<string, IPartnersFeatureBlockApiModel>;
    title?: string;
}

type CardData = {
    title: string;
    description: string;
    imageSrc: string;
    imageAlt: string;
    className?: string;
};

const ServiceFeature: FC<Props> = props => {
    const {
        sectionBlocks,
        title,
    } = props;
    const {
        allInOneCabinet,
        referralLinks,
        apiIntegrations,
    } = sectionBlocks;

    const cardsData = useMemo<CardData[]>(
        () => [
            {
                title: allInOneCabinet.title,
                description: allInOneCabinet.description,
                imageSrc: '/next/assets/service-cabinet-feature.png',
                imageAlt: 'online cabinet',
                className: buildClassNames(styles, ['card', 'card--first']),
            },
            {
                title: referralLinks.title,
                description: referralLinks.description,
                imageSrc: '/next/assets/service-ref-feature.png',
                imageAlt: 'referral links',
                className: buildClassNames(styles, ['card', 'card--second']),
            },
            {
                title: apiIntegrations.title,
                description: apiIntegrations.description,
                imageSrc: '/next/assets/service-api-feature.svg',
                imageAlt: 'api integrations',
                className: buildClassNames(styles, ['card', 'card--third']),
            },
        ],
        [allInOneCabinet, referralLinks, apiIntegrations]
    );

    const cards = useMemo(
        () =>
            cardsData.map((item, index) => (
                <div className={item.className} key={index}>
                    <div className={styles['card__title-container']}>
                        <h3 className={styles['card__title']}>{item.title}</h3>
                    </div>
                    <div className={styles['card__description-container']}>
                        <p className={styles['card__description']}>{item.description}</p>
                    </div>
                    <div className={styles['card__image-container']}>
                        <div className={styles['card__image']}>
                            <Image src={item.imageSrc} alt={item.imageAlt} fill />
                        </div>
                    </div>
                </div>
            )),
        [cardsData]
    );

    return (
        <div className={styles['feature-wrapper']}>
            <h2 className={styles['feature-title']}>{title}</h2>
            <div className={styles['feature']}>
                <div className={styles['feature__slider']}>
                    <PartnerWorkflowSlider showNextSlide>
                        {cards}
                    </PartnerWorkflowSlider>
                </div>
                <div className={styles['feature__cards']}>{cards}</div>
            </div>
        </div>
    );
};

export default ServiceFeature;
